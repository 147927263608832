<template>
    <div id="face-recognition-register-information">
        <vx-card>
            <div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Chi nhánh</label>
                        <v-select class="" v-model="branchModel" :clearable="false" :options="branchOptions"
                                  data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                  name="branch">
                                  <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Mã nhân viên" :disabled="isUpdate"
                                  v-model="employeeInfo.employeeCode"
                                  v-validate="'required'" name="employeeCode"/>
                        <span class="text-danger text-sm">{{ errors.first('employeeCode') }}</span>
                    </div>
                </div>
                <!-- Content Row -->
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Họ" v-model="employeeInfo.firstName"
                                  v-validate="'required'" name="firstName"/>
                        <span class="text-danger text-sm">{{ errors.first('firstName') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Tên" v-model="employeeInfo.lastName"
                                  v-validate="'required'" name="lastName"/>
                        <span class="text-danger text-sm">{{ errors.first('lastName') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Ngày sinh</label>
                        <datepicker :format="'dd/MM/yyyy'" v-model="employeeInfo.birthday"
                                    :disabledDates="disabledFn"
                                    data-vv-validate-on="input" v-validate="'required'" name="birthday"></datepicker>
                        <span class="text-danger text-sm">{{ errors.first('birthday') }}</span>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Giới tính</label>
                        <v-select class="" v-model="genderModel" :clearable="false" :options="genderOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="gender"/>
                        <span class="text-danger text-sm">{{ errors.first('gender') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Email" v-model="employeeInfo.email"
                                  v-validate="'email'" name="email"/>
                        <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Số điện thoại" v-model="employeeInfo.phoneNumber"
                                  v-validate="'numeric|min:10|max:11'" name="phoneNumber"/>
                        <span class="text-danger text-sm">{{ errors.first('phoneNumber') }}</span>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Tỉnh/Thành phố</label>
                        <v-select class="" v-model="province" :clearable="false" :options="cityOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="organizationType"/>
                        <span class="text-danger text-sm">{{ errors.first('city') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Quận/Huyện</label>
                        <v-select class="" v-model="district" :clearable="false" :options="districtOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="organizationType"/>
                        <span class="text-danger text-sm">{{ errors.first('district') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">Xã/Phuờng</label>
                        <v-select class="" v-model="subDistrict" :clearable="false" :options="subDistrictOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="organizationType"/>
                        <span class="text-danger text-sm">{{ errors.first('subDistrict') }}</span>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Số nhà(*)" v-model="employeeInfo.houseNumber"
                                  v-validate="'required|max:50'" name="houseNumber"/>
                        <span class="text-danger text-sm">{{ errors.first('houseNumber') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Địa chỉ" v-model="fullAddressModel"
                                  v-validate="''" name="full_address" readonly="true"/>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" label="Vị trí" v-model="employeeInfo.position"
                                  v-validate="" name="position"/>
                        <span class="text-danger text-sm">{{ errors.first('position') }}</span>
                    </div>
                </div>
                <div class="vx-row">

                    <div class="vx-col md:w-2/3 w-full mt-4">
                        <label class="vs-input--label">Dữ liệu khuôn mặt(10 ảnh)</label>
                        <div class="vx-row">
                            <div class="vx-col">
                                <vue-upload-multiple-image
                                    @upload-success="uploadImageSuccess"
                                    @before-remove="beforeRemove"
                                    @edit-image="editImage"
                                    @data-change="dataChange"
                                    :data-images="images"
                                ></vue-upload-multiple-image>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="flex flex-wrap items-center justify-end">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                    `${isUpdate ?
                                        'Cập nhật' : 'Tạo mới'}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="$router.back()">Quay lại
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>

    </div>
</template>

<script>

import vSelect from 'vue-select'
import province from '@/assets/utils/province.js';
import Datepicker from 'vuejs-datepicker';
import utils from "../../../components/utils";
import VueUploadMultipleImage from '../../../components/VueUploadMultipleImage'

export default {
    components: {
        vSelect,
        Datepicker,
        VueUploadMultipleImage,
    },
    data() {
        return {
            images: [],
            imagesId: [],
            isUpdateImages: false,
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            isUpdateFace: false,
            branchOptions: [],
            employeeInfo: {},
            disabledFn: {
                customPredictor(date) {
                    if (date.getTime() >= new Date().getTime()) {
                        return true; // returns true if disabled
                    }
                }
            },
            workShiftSetting: [],
            timeSpans: [],
            genderOptions: [
                {value: 0, label: 'Không xác định'},
                {value: 1, label: 'Nữ'},
                {value: 2, label: 'Nam'}
            ],
            cityOptions: province.getProvince(),
            districtOptions: [],
            subDistrictOptions: [],
            file: [],
            fileName: 'Thêm dữ liệu khuôn mặt.',
            uploadPercent: 0,
        }
    },
    async created() {
        this.cityOptions.unshift({value: null, label: 'Tất cả'});
        this.districtOptions.unshift({value: null, label: 'Tất cả'});
        this.subDistrictOptions.unshift({value: null, label: 'Tất cả'});
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });
        this.employeeInfo = {
            organizationBranchName: null,
            city: null,
            district: null,
            fullAddress: null,
            houseNumber: null,
            organizationBranchType: 0,
            organizationBranchId: this.$route.query.branch_id,
            phoneNumber: null,
            street: null,
            gender: 0,
            type: 0,
            salary: 0,
            departmentId: null,
            departmentType: 0,
        };
        let employeeId = this.$route.query.id;
        if (employeeId) {
            await this.getUserInfo(employeeId);
            for (const x of this.employeeInfo.imageStore) {
                let data = await this.toDataURL(x.url);
                let dataImage = data.xhr.response;
                let metadata = {
                    type: 'image/jpeg'
                };
                let file = new File([dataImage], x.fileName, metadata);
                this.images.push({
                    name: x.fileName,
                    path: data.imgBase64,
                    highlight: 1,
                    default: 1,
                    file: file
                });
            }
        }
    },
    computed: {
        branchModel: {
            get() {
                return {
                    label: this.getLabelString(this.branchOptions.find(x => x.value == this.employeeInfo.organizationBranchId)),
                    value: this.employeeInfo.organizationBranchId
                }
            },
            set(obj) {
                this.employeeInfo.organizationBranchId = obj.value;
            }
        },
        validateForm() {
            return !this.errors.any()
        },
        genderModel: {
            get() {
                return {
                    label: this.genderOptions.find(x => x.value === this.employeeInfo.gender).label,
                    value: this.employeeInfo.gender
                }
            },
            set(obj) {
                this.employeeInfo.gender = obj.value;
            }
        },
        employeeTypeModel: {
            get() {
                return {
                    label: this.employeeTypeOptions.find(x => x.value === this.employeeInfo.type).label,
                    value: this.employeeInfo.type
                }
            },
            set(obj) {
                this.employeeInfo.type = obj.value;
            }
        },
        province: {
            get() {
                return {
                    label: this.getLabelString(this.cityOptions.find(x => x.value === this.employeeInfo.city)),
                    value: this.employeeInfo.city
                }
            },
            set(obj) {
                if (obj.value) {
                    this.employeeInfo.city = obj.value;
                    this.districtOptions = province.getDistrict(obj.value);
                    this.employeeInfo.district = this.districtOptions[0].value;
                    this.subDistrictOptions = province.getSubDistrict(this.employeeInfo.city, this.employeeInfo.district);
                    this.employeeInfo.street = this.subDistrictOptions[0].value;
                } else {
                    this.employeeInfo.city = null;
                    this.districtOptions = [{value: null, label: 'Tất cả'}];
                    this.employeeInfo.district = null;
                    this.subDistrictOptions = [{value: null, label: 'Tất cả'}];
                    this.employeeInfo.street = null;
                }
            }
        },
        district: {
            get() {
                return {
                    label: this.getLabelString(this.districtOptions.find(x => x.value === this.employeeInfo.district)),
                    value: this.employeeInfo.district
                }
            },
            set(obj) {
                this.employeeInfo.district = obj.value;
                this.subDistrictOptions = province.getSubDistrict(this.employeeInfo.city, obj.value);
                this.employeeInfo.street = this.subDistrictOptions[0].value;
            }
        },
        subDistrict: {
            get() {
                return {
                    label: this.getLabelString(this.subDistrictOptions.find(x => x.value === this.employeeInfo.street)),
                    value: this.employeeInfo.street
                }
            },
            set(obj) {
                this.employeeInfo.street = obj.value
            }
        },
        fullAddressModel: {
            get() {
                if (this.province.value) {
                    let fullAddress = `${this.employeeInfo.houseNumber ? this.employeeInfo.houseNumber + ', ' : ''}${this.subDistrict.label ? this.subDistrict.label + ', ' : ''}${this.district.label}, ${this.province.label}`;
                    this.employeeInfo.fullAddress = fullAddress;
                    return fullAddress;
                } else {
                    return null;
                }
            },
        }
    },
    methods: {
        toDataURL(url) {
            return new Promise(resolve => {
                let xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    let reader = new FileReader();
                    reader.onloadend = function () {
                        resolve({imgBase64: reader.result, xhr: xhr});
                    }
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            })
        },
        getUserInfo(employeeId) {
            return new Promise(resolve => {
                this.$vs.loading();
                this.$crm.get('/face-recognition-register/find-by-id/' + employeeId).then(async (response) => {
                    this.employeeInfo = response.data;
                    this.districtOptions = province.getDistrict(this.employeeInfo.city);
                    this.subDistrictOptions = province.getSubDistrict(this.employeeInfo.city, this.employeeInfo.district);
                    this.isUpdate = true;
                    this.$vs.loading.close();
                    resolve(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },

        uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
            this.setIsUpdateImages();
        },
        beforeRemove(index, done, fileList) {
            this.images = fileList;
            this.setIsUpdateImages();
            done();
        },
        editImage(formData, index, fileList) {
            this.images = fileList;
            this.setIsUpdateImages();
        },
        dataChange() {
            //this.images = fileList;
            this.setIsUpdateImages();
        },
        setIsUpdateImages() {
            if (this.isUpdate) {
                this.isUpdateImages = true;
            }
        },
        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        async saveChanges(evt) {
            evt.preventDefault();
            if (!this.validateForm) return;

            const result = await this.$validator.validateAll();
            if (!result) return;

            if (!this.employeeInfo.city) {
                return this.$vs.notify({
                    title: 'Error',
                    text: "Vị trí địa lý chưa được chọn",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            }
            if (this.images.length === 0)
                return this.$vs.notify({
                    text: 'Avata chưa được chọn. ',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });

            this.employeeInfo.fullName = this.employeeInfo.firstName.trim() + " " + this.employeeInfo.lastName.trim();

            // if (this.isUpdateImages) {
            //   await this.handleClickUploadFile();
            //   this.employeeInfo.images = this.imagesId;
            // }
            this.employeeInfo.images = [202, 203];
            let url = this.isUpdate ? `/face-recognition-register/update` : `/face-recognition-register/create`,
                data = this.isUpdate ? this.employeeInfo : [this.employeeInfo];
            this.$vs.loading();
            this.$crm.post(url, data).then(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                    color: 'success',
                    text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} nhân sự thành công`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$router.push(`/user/manage-employee${this.employeeInfo.organizationBranchId ?
                    `?branch_id=${this.employeeInfo.organizationBranchId}` : ''}`).catch(() => {
                })
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        confirmHandleClickUploadFile() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Dữ liệu cũ sẽ bị ghi đè. Bạn có muốn ghi đè không?`,
                accept: this.handleClickUploadFile,
                acceptText: "Đồng ý",
                cancelText: "Huỷ"
            })
        },
        handleClickUploadFile() {
            return new Promise((resolve) => {
                let that = this;
                this.uploadPercent = 0;

                let formData = new FormData(), config = {};
                this.images.forEach((f) => {
                    formData.append('files', f.file);
                });
                config = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    onUploadProgress: function (progressEvent) {
                        let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        if (percent < 90)
                            that.uploadPercent = percent;
                    }
                };
                this.$vs.loading();
                this.$crm.post('/employee/image/upload', formData, config).then((res) => {
                    this.uploadPercent = 100;
                    this.imagesId = [];
                    this.imagesId = res.data;
                    resolve(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            });
        },
        employeeList() {
            this.$router.push(`/user/manage-employee${this.employeeInfo.organizationBranchId ? `?branch_id=${this.employeeInfo.organizationBranchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>
